import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import ContentText from '../Typography/ContentText'

const useStyles = makeStyles((theme) => ({
  table: {
    '& th': {
      paddingRight: theme.spacing(1),
      '& div': {
        fontWeight: 500,
      },
    },
  },
  bankTitle: {
    '& div': {
      textAlign: 'center',
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
  },
}))

const BankaCard = ({ cardImage, bankTitle, hesapAdi, sube, hesapNo, iban }) => {
  const classes = useStyles()
  return (
    <Card>
      <Box textAlign='center' p={2}>
        <img src={cardImage} height={58} title={bankTitle} alt={bankTitle} />
      </Box>
      <Divider />
      <CardContent>
        <Box className={classes.bankTitle}>
          <ContentText>{bankTitle}</ContentText>
        </Box>
        <table className={classes.table}>
          <tbody>
            <tr>
              <th>
                <ContentText>Hesap adı:</ContentText>
              </th>
              <td>
                <ContentText>{hesapAdi}</ContentText>
              </td>
            </tr>
            <tr>
              <th>
                <ContentText>Şube:</ContentText>
              </th>
              <td>
                <ContentText>{sube}</ContentText>
              </td>
            </tr>
            <tr>
              <th>
                <ContentText>Hesap No:</ContentText>
              </th>
              <td>
                <ContentText>{hesapNo}</ContentText>
              </td>
            </tr>
            <tr>
              <th>
                <ContentText>IBAN:</ContentText>
              </th>
              <td>
                <ContentText>{iban}</ContentText>
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  )
}
export default BankaCard
